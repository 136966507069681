<template>
  <v-menu
    offset-y
    bottom
    left
    z-index="99"
    :close-on-content-click="false"
    content-class="notification-dropdown"
    transition="slide-y-transition"
    nudge-bottom="15"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-tooltip="'通知'" text icon v-on="on">
        <v-badge v-if="notifications.length" right overlap color="red" dot>
          <span slot="badge" />
          <i class="feather-bell font-lg grey--text" />
        </v-badge>
        <i v-else class="feather-bell font-lg grey--text" />
      </v-btn>
    </template>

    <div class="dropdown-content">
      <v-card class="sweeep-popover">
        <v-card-title height="50">
          <span class="white--text fw-bold">
            {{
              notifications.length > 0
                ? `通知が${notifications.length}件あります`
                : '現在通知はありません'
            }}
          </span>
          <v-spacer />
          <v-btn
            v-if="notifications.length > 0"
            small
            @click="readAllNotification()"
          >
            <s-icon class="tw-pr-2" icon="zmdi zmdi-email-open" />
            全て既読
          </v-btn>
        </v-card-title>
        <v-list class="dropdown-list" dense theree-line>
          <v-list-item
            v-for="(notification, index) in notifications"
            :key="notification.id"
          >
            <v-list-item-content>
              <div class="fs-10 grey--text pt-2">
                {{ getCreatedAt(notification.date_created) }}
              </div>
              <div
                class="fs-11 fw-bold py-1"
                style="
                  color: #00000099;
                  white-space: pre-wrap;
                  word-wrap: break-word;
                "
                @click="readNotifications(notification.id, index)"
                v-html="notification.notification_detail"
              />
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-tooltip="'既読にする'"
                icon
                @click="readNotifications(notification.id, index)"
              >
                <s-icon size="xl" icon="zmdi zmdi-email-open" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </v-menu>
</template>

<script>
import axios from 'axios'
import Snackbar from 'Helpers/snackbar/index'

export default {
  data() {
    return {
      notifications: [],
    }
  },
  watch: {
    $route: function () {
      this.getNotificationList()
    },
  },
  mounted() {
    this.getNotificationList()
  },
  methods: {
    getNotificationList() {
      try {
        let apiUrl = this.$store.getters.apiNotificationUrl
        axios
          .get(apiUrl, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
            params: {
              organization_id: this.$store.getters.getOrganizationID,
              user_organization_id: this.$store.getters.getUserOrganizationID,
            },
          })
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
              return
            }
            this.notifications = response.data.notifications
          })
      } catch (e) {
        console.log(e)
      }
    },
    readNotifications(id, index) {
      try {
        let apiUrl = this.$store.getters.apiNotificationUrl
        axios
          .put(
            apiUrl,
            {
              userOrganizationId: this.$store.getters.getUserOrganizationID,
              notificationId: id,
            },
            { headers: { Authorization: this.$store.getters.getAuthToken } }
          )
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
              return
            }
            this.notifications.splice(index, 1)
          })
      } catch (e) {
        console.log(e)
      }
    },
    readAllNotification() {
      let apiUrl = this.$store.getters.apiNotificationUrl
      axios
        .put(
          apiUrl,
          {
            userOrganizationId: this.$store.getters.getUserOrganizationID,
            readAll: true,
          },
          { headers: { Authorization: this.$store.getters.getAuthToken } }
        )
        .then((response) => {
          if (response.data.deleted) {
            this.notifications = []
          }
        })
    },
    getCreatedAt(notificationDate) {
      try {
        let dateString = notificationDate.slice(0, 16).replace('T', ' , ')
        return dateString
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
